export enum ItemTypes {
    folder,
    pdf,
    video,
    image,
    txt,
    app,
    link,
    widget,
    safariWidget,
    chromeWidget,
    gameWidget,
    gameWidgetSpinMaster,
    gameWidgetTankieBattleGround,
    gameWidgetBacterialDefender,
    gameWidgetBulletFever,
    gameWidgetStairClimber,
  }