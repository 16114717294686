import React from "react";
import axios from "axios";
import Draggable from "react-draggable";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

function PriceWidget() {
  const [price, setPrice] = React.useState(null);
  const [percentChange24h, setPercentChange24h] = React.useState(null);
  const [chartData, setChartData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const fetchPrice = async () => {
      try {
        setLoading(true);

        // **CoinGecko API Call**
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/coins/cardano/market_chart",
          {
            params: {
              vs_currency: "usd",
              days: 2,
              interval: "daily",
            },
          }
        );

        const priceData = response.data.prices.map((entry) => entry[1]);
        const latestPrice = priceData[priceData.length - 1];
        const previousPrice = priceData[0];
        const percentChange = ((latestPrice - previousPrice) / previousPrice) * 100;

        setPrice(latestPrice.toFixed(2));
        setPercentChange24h(percentChange.toFixed(2));
        setChartData({
          labels: ["Yesterday", "Today"],
          datasets: [
            {
              label: "Price (USD)",
              data: [previousPrice, latestPrice],
              borderColor: percentChange >= 0 ? "green" : "red",
              borderWidth: 2,
              pointRadius: 2,
              fill: false,
              tension: 0.3,
            },
          ],
        });

        setLoading(false);
      } catch (err) {
        console.error("Error fetching Cardano price:", err);
        setError(true);
        setLoading(false);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 60000); // Update every 1 minute
    return () => clearInterval(interval);
  }, []);

  return (
    <Draggable defaultPosition={{ x: 500, y: -250 }}>
      <div
        className="price-widget"
        style={{
          padding: "16px",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          cursor: "move",
          maxWidth: "300px",
          minWidth: "280px",
          overflow: "hidden",
          zIndex: 1000,
        }}
      >
        <h4 style={{ marginBottom: "8px", fontWeight: 600, textAlign: "center" }}>
          Cardano (ADA) Price
        </h4>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p style={{ color: "red", textAlign: "center" }}>Failed to load price.</p>
        ) : (
          <>
            <div
              style={{
                fontSize: "1.5rem",
                marginBottom: "8px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              ${price} USD
            </div>
            <div
              style={{
                textAlign: "center",
                color: percentChange24h >= 0 ? "green" : "red",
                fontSize: "1.2rem",
              }}
            >
              {percentChange24h >= 0 ? "↑" : "↓"} {Math.abs(percentChange24h)}% (24h)
            </div>
            <div style={{ marginTop: "16px" }}>
              <Line
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        label: (tooltipItem) => `Price: $${tooltipItem.raw.toFixed(2)}`,
                      },
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                      ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                      },
                    },
                    y: {
                      grid: {
                        color: "rgba(0, 0, 0, 0.1)",
                      },
                    },
                  },
                }}
                height={200}
              />
            </div>
          </>
        )}
      </div>
    </Draggable>
  );
}

export default PriceWidget;
